/* Job MT tenant styles */

@import "../colors";
@import "../settings";

// Guest specific
.kmta__tenant--job-mt.is-guest {
    // Tweak the padding for guest as the header doesn't contain the user names, etc.
    header.kmta__header a.kmta__tenantLogoAlt img {
        margin-top: -5px;
    }
}

// After sign-in
.kmta__tenant--job-mt {
    header.kmta__header {
        a.kmta__tenantLogoAlt {
            margin-right: 0px;

            img {
                margin-top: 5px;
                margin-right: 20px;

                height: 53px;
                width: 260px;
            }
        }
    }

    .kmta__faqHome {
        background: linear-gradient(
            to bottom,
            rgba(236, 237, 237, 1) 0%,
            rgba(198, 199, 200, 1) 100%
        );
    }

    .job-mt_svgIcon--logo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #fff;
        fill: #000000;
        border: 1px solid var(--f-1-primary-color);
        margin-top: -33px;
    }

    .kmta__contactperson.is-expanded {
        height: 250px;
    }
    @include breakpoint(small down) {
        .kmta__headerUserinfoWrapper {
            height: 38px;
        }
        .kmta__headerBottomRow {
            a.job-mt__logo {
                width: 0;
                flex-grow: 1;
            }
            .kmta__headerNavWrapper {
                align-self: end;
            }
        }
    }
}
